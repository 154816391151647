@media screen and (max-width: 600px) {
    .hero-cop26 {
        font-size: 2.4em;
    }

    #welcome-section .welcome-img {
        width: 70% !important;
    }

    .welcome-text-wrapper .col {
        font-size: 0.9em;
    }

    .welcome-text-wrapper .container-text-right-col {
        padding: 0 5%;
    }

    .welcome-video-container {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
    }

    .welcome-credits-container {
        padding: 10vh 0 15vh 0;
    }

    .credits-text:nth-child(1),
    .credits-text:nth-child(2) {
        text-decoration: underline;
    }

    .figure-text-wrapper {
        padding: 2vh 0;
    }

    .sidebar-eye,
    .sidebar-eye-hover,
    .sidebar-eye-open,
    .sidebar-eye-hover-open,
    .sidebar-eye-white {
        padding-right: 0;
    }

    .sidebar-menu {
        border: 4px solid #29ff42;
    }

    .sidebar-menu-row {
        padding-top: 1em;
    }

    /* intersections */

    .inter-figure-text-wrapper {
        gap: 2em;
        padding: 3vh 0;
    }

    .inter-figure-text-wrapper .figure {
        padding: 0% 4%;
    }

    .first-inter-text {
        padding-top: 1em;
    }

    .fourth-section-hero-heading {
        font-size: 30px;
    }

    .fourth-section-hero-link-wrapper {
        margin: 4em 0;
    }

    .fourth-intersection-img-caption {
        font-size: 20px;
    }

    .fourth-section-hero-link {
        padding: 15px;
    }

    .fifth-section-hero-wrapper {
        max-width: 95%;
    }

    .fifth-section-hero-heading {
        font-size: 36px;
    }

    .fifth-section-hero-link-wrapper {
        margin: 12vh 0;
    }

    .fifth-section-hero-link {
        padding: 15px;
    }

    /*  main menu */

    .main-menu-item-text:hover .main-background-div {
        display: none;
    }

    /* sidebar */
    .sidebar-menu {
        width: 100%;
    }

    .sidebar-menu-item {
        margin: 10px 0;
    }

    .sidebar-menu-item-arrow {
        display: none;
    }

    .sidebar-carousel-container {
        width: 0%;
    }

    .sidebar-carousel-slide {
        right: 100%;
    }

    /* section general */

    .heading-text {
        max-width: 90%;
        padding: 0;
    }

    .figure-text-wrapper,
    .container-text-wrapper {
        grid-template-columns: 1fr;
    }

    .figure-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 5%;
    }

    /* .figure-wrapper-col {
        display: flex;
        justify-content: center;
        gap: 2em;
        padding: 0 5%;
    } */

    .section-hero-container .figure {
        max-width: 100%;
    }

    .figure {
        margin: 1em auto;
        padding: 0;
        float: none;
        max-width: 100%;
        display: flex;
    }

    .container-text-col {
        padding: 0 5%;
    }

    .figure-text-wrapper .d-flex {
        padding: 0 !important;
    }

    .wavy-arrow {
        display: none;
    }

    .more-info-wrapper {
        text-decoration: underline;
        margin: 0;
        padding: 0;
        padding-bottom: 0.3em;
    }

    .figcaption-text {
        font-size: 0.7em;
        word-break: break-word;
    }

    .plus-icon {
        width: 1em;
    }

    /*  making waves */

    .making-waves-heading {
        font-size: 3em;
    }

    .making-waves-container {
        width: 100%;
    }

    .making-waves-text-wrapper {
        width: 100%;
    }

    .making-waves-url-wrapper {
        margin-left: 0;
        padding: 0;
        text-decoration: underline;
    }

    .making-waves-link:hover {
        color: #000;
        border: none;
    }

    #christina-wong-container .making-waves-text-wrapper {
        margin: 0 1em;
        width: 90%;
    }

    #sculpture-1 {
        left: 5%;
        top: -10%;
        max-width: 16%;
    }

    #sculpture-2 {
        left: -4%;
    }

    #sculpture-3 {
        bottom: 6%;
    }

    #sculpture-4 {
        right: -3%;
        bottom: 6%;
    }

    #zero-waste-1 {
        display: none;
    }

    #james-pfaff-2 {
        right: 0;
    }

    #james-pfaff-3 {
        right: -8%;
    }

    #potr-3 {
        right: 2%;
        bottom: 2%;
    }

    #karen-1 {
        display: none;
    }

    /* sustainable */

    .sustainable-container-1 {
        background-image: none;
    }

    .sustainable-container-1 .row {
        display: flex;
        flex-direction: column;
    }

    .sustainable-1-left-col {
        order: 0;
        padding-top: 0;
    }

    .sustainable-heading {
        position: static;
        padding: 10% !important;
        order: 1;
        background-image: url('../assets/img/sustainable/sustainable-line-1.png');
        background-repeat: no-repeat;
        background-size: contain;
    }

    .sustainable-1-right-col {
        order: 2;
    }

    .sustainable-figure-17 {
        width: 90%;
    }

    /*  after november  */

    .after-november-container-1 {
        background-image: none;
    }

    .after-november-section {
        padding-bottom: 0;
    }

    .after-november-line-3 {
        position: static;
    }

    .after-november-heading-text {
        padding-left: 5%;
    }

    .after-november-container-1 .row {
        grid-template-columns: 1fr;
        padding-bottom: 0;
    }

    .after-november-figure-2 {
        padding: 0 15%;
    }

    .november-line-wrapper {
        min-height: 2%;
        height: 2%;
    }

    /* climate literacy  */

    .climate-literacy-container-1 {
        background-image: none;
    }

    .climate-literacy-container-1 .row {
        display: flex;
        flex-direction: column;
    }

    .climate-literacy-1-left-col {
        order: 0;
        padding-top: 0;
    }

    .climate-literacy-heading {
        position: static;
        padding: 10% !important;
        order: 1;
        background-image: url('../assets/img/climate-literacy/climate-literacy-line-1.png');
        background-repeat: no-repeat;
        background-size: contain;
    }

    .climate-literacy-1-right-col {
        order: 2;
    }

    /* close-of-play */

    .close-of-main-wrapper {
        display: flex;
        flex-direction: column-reverse;
    }

    .close-of-text {
        margin: 0 !important;
        padding: 0;
    }

    .close-of-text-row {
        order: 1;
    }

    /*Snap scroll*/
    html {
        -webkit-scroll-snap-type: none;
        -ms-scroll-snap-type: none;
        scroll-snap-type: none;
    }
}
